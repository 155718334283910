$spacing-big: 32px;
$spacing-standard: 16px;
$spacing-half: 8px;

$font-biggest: 1.6em;
$font-big: 1.35em;
$font-medium: 1.15em;
$font-standard: 1em;
$font-half: 0.8em;

$shadow-light: #999;
$shadow-mid: #666;
$shadow-mid-dark: #333;
$shadow-dark: black;

$shadow-range-big: 8px;
$shadow-range-standard: 4px;
$shadow-range-short: 2px;