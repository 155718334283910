@import 'style-constants';

html {
    background: #336 no-repeat center center fixed;
    overflow-x: hidden;

    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: $font-standard;
    font-weight: lighter;
    color: white;
    text-shadow: $shadow-mid-dark 0 0 $shadow-range-short;
}

body {
    margin: 0;
    padding: $spacing-standard;

    background-image: url('./assets/ag-bg5adjsm.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 100vh;
    height: auto;
    min-width: 100vw;
    width: 100vw;
}

input, select {
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

* {
    box-sizing: border-box;
}

.row {
    min-height: 32px;
    display: flex;
    flex-wrap: wrap;
}

.width-100 {
    width: 100%;
}

.width-50 {
    width: 50%;
}

.width-75 {
    width: 75%;
}

.width-25 {
    width: 25%;
}

.spacer {
    flex: 1 1 auto;
}

.spacer-verti {
    flex: 1;
}

.padding-standard, .padding-all-standard {
    padding: $spacing-standard;
}

.padding-horiz-standard {
    padding: 0 $spacing-standard 0 $spacing-standard;
}

.padding-verti-standard {
    padding: $spacing-standard 0 $spacing-standard 0;
}

.margin-standard, .margin-all-standard {
    margin: $spacing-standard;
}

.margin-horiz-standard {
    margin: 0 $spacing-standard 0 $spacing-standard;
}

.margin-verti-standard {
    margin: $spacing-standard 0 $spacing-standard 0;
}

// Tablet portrait
@media only screen
       and (max-device-width: 768px) 
       and (orientation: portrait) {
    html {
        overflow-x: hidden;
        overflow-y: initial;
    }

    body {
        background-image: url('./assets/ag-bg5adjsm.png');
    }
}

// Tablet landscape
@media only screen 
       and (max-device-width: 1024px)
       and (orientation: landscape) {
    html {
        overflow-x: hidden;
        overflow-y: initial;
    }

    body {
        background-image: url('./assets/ag-bg5adjsm.png');
    }
}

// Phone portrait
@media only screen 
       and (max-device-width: 460px) 
       and (orientation: portrait) {
    html {
        min-height: auto;
        height: auto;
        overflow-x: hidden;
        overflow-y: initial;
        font-size: $font-half;
    }

    body {
        background-image: url('./assets/ag-bg5adjsm.png');
    }

    .padding-standard, .padding-all-standard {
        padding: $spacing-half;
    }

    .padding-verti-standard {
        padding: 0 $spacing-half 0 $spacing-half;
    }

    .margin-verti-standard {
        margin: 0 $spacing-half 0 $spacing-half;
    }
}

// Phone landscape
@media only screen
       and (max-device-width: 750px)
       and (orientation: landscape) {
    html {
        min-height: auto;
        height: auto;
        overflow-x: initial;
        overflow-y: initial;
        font-size: $font-half;
    }

    body {
        background-image: url('./assets/ag-bg5adjsm.png');
    }
    
    .padding-standard, .padding-all-standard {
        padding: $spacing-half;
    }

    .padding-verti-standard {
        padding: 0 $spacing-half 0 $spacing-half;
    }

    .margin-verti-standard {
        margin: 0 $spacing-half 0 $spacing-half;
    }
}
